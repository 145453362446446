import { useParams } from 'react-router-dom';
import TableLayout from '../../../Components/handle_table/TableLayout';
import { useEffect, useState } from 'react';
import Notes from './Notes';
import Skeleton from 'react-loading-skeleton'
import SelectLangOne from '../../../Components/handle_select_one/SelectLangOne';
import { updateLead } from '../../../helpers/funcs';
import { get, post, put } from '../../../helpers/apiHandler';
import { Store } from 'react-notifications-component';
import isoToCountry from '../../../docs/isoToCountry';
import SelectStatusOne from '../../../Components/handle_select_one/SelectStatusOne';
import { currentUser0, forceUpdateTrans0, lastCall0, leadsTable0 } from '../../../helpers/states';
import { useRecoilState } from 'recoil';
import AddTran from './AddTran';
import { CiMail, CiStar } from "react-icons/ci";
import { BsTelephoneFill } from 'react-icons/bs';
import { FaStar } from 'react-icons/fa';


const LeadPage = () => {
    const [lead, setLead] = useState()
    let { leadId } = useParams();
    const objBaseLogs = {"description":[],"created_at":[]}
    const objBaseTrans = {"amount":[],"date":[]}

    const [forceUpdateTrans, setForceUpdateTran] = useRecoilState(forceUpdateTrans0);
    const [forceUpdateLogs, setForceUpdateLogs] = useState(100);

    useEffect(() => {
        setForceUpdateTran(prev => prev + 1);
        setForceUpdateLogs(prev => prev + 1);
    }, [leadId])


    const fetchLead = async () => {
        const result = await get(`/lead/${leadId}`)
        if (result.ok) {
            setLead(result.data)
        } else {
            setLead({})

            Store.addNotification({
                message: result.data.message || `somthing went wrong`,
                type: "danger",
                insert: "bottom",
                container: "bottom-right",
                dismiss: {
                  duration: 5000,
                }
            });

        }
    }
    useEffect(() => {
        fetchLead()
    }, [])

    const [leadLang, setLeadLang] = useState(lead?.lang || '')
    useEffect(() => {
        if (lead !== undefined) {
            setLeadLang(lead?.lang || '')
        }
    }, [lead])

    const [selectedStatus, setSelectedStatus] = useState(lead?.status || '')
    useEffect(() => {
        if (lead !== undefined) {
            setSelectedStatus(lead.status)
        }
    }, [lead])

    const [currentUser, setCurrentUser] = useRecoilState(currentUser0)

    const getAllThsTrans = () => {
        if (currentUser.access_level >= 3) return allThsTrans;
        return allThsTrans.filter(item => item.key !== 'delete');
    }

    //////////////////////////////////// update ////////////
    const [isFav, setIsFav] = useState(lead?.favorite || false)
    const [leadsTable, setLeadsTable] = useRecoilState(leadsTable0)

    useEffect(() => {
        if (lead !== undefined) {
            if (leadLang !== '' && selectedStatus !== '') {
                if (leadsTable['leads'] !== undefined) {
                    setLeadsTable(prev => {
                        var indexLead = prev['leads']['data'].findIndex(item => item.id == leadId)
                        var lead_from_data = prev['leads']['data'][indexLead]
                        if (lead_from_data['lang'] !== leadLang || lead_from_data['status'] !== selectedStatus || isFav !== lead_from_data['favorite']) {
                            var obj = {
                                lang: leadLang, 
                                status: selectedStatus,
                                favorite: isFav
                            }
                            var newData = [...prev['leads']['data']]
                            newData[indexLead] = {
                                ...newData[indexLead],
                                ...obj
                            }
                            return {
                                ...prev,
                                leads: {
                                    ...prev['leads'],
                                    data: [...newData]
                                }
                            }
                        }
                        return prev
                    })
                }
                if (leadLang !== lead?.lang) {
                    updateLead(leadId, {'lang': leadLang})
                }
                if (selectedStatus !== lead?.status) {
                    updateLead(leadId, {'status': selectedStatus})
                }
                if (isFav !== (lead?.favorite)) {
                    updateLead(leadId, {'favorite': isFav})
                }
            }
        }
    }, [selectedStatus, leadLang, isFav])



    const handleIsFav = async () => {
        setIsFav(prev => !prev)
    }
    useEffect(() => {
        if (lead !== undefined) {
            if (lead.favorite !== isFav) {
                setIsFav(lead.favorite)
            }
        }
    }, [lead])


    const [lastCall, setLastCall] = useRecoilState(lastCall0)
    const makeCall = async () => {
        Store.addNotification({
            message: "Call in progress",
            type: 'success',
            insert: "bottom",
            container: "bottom-right",
            dismiss: {
              duration: 5000,
            }
        });
        setLastCall(leadId)
        const res = await get(`/lead/${leadId}/call`, {callerid: currentUser.callerid})
        if (res.ok) {

        } else {
            Store.addNotification({
                message: res.data.message || `somthing went wrong`,
                type: "danger",
                insert: "bottom",
                container: "bottom-right",
                dismiss: {
                  duration: 5000,
                }
            });
        }
    }
    

    return (
        <div className='grid grid-cols-5 gap-4'>

            <div className='card p-0 col-span-full lg:col-span-3'>
                <div className='grid grid-cols-3 lg:grid-cols-4 gap-0'>

                    <div className='col-span-full border-b p-4 flex flex-row justify-between'>
                        <div className='flex flex-row space-x-5 items-center'>
                            <div className='bg-violet-400 w-20 h-20 flex items-center justify-center rounded-full text-white text-[27px] font-semibold'>
                                {lead?.first_name.slice(0,1)}
                                {lead?.last_name.slice(0,1)}
                            </div>
                            <div className='flex flex-col'>
                                <div className='flex flex-row space-x-1.5 text-2xl font-semibold items-center'>
                                    {
                                        typeof lead === typeof undefined
                                        ? <Skeleton className='w-[150px]' />
                                        : <span>{lead?.first_name} {lead?.last_name}</span>
                                    }
                                    <div className="cursor-pointer text-[20px]" onClick={handleIsFav}>
                                        {
                                            isFav ? (
                                                <FaStar className="text-blue-500" />
                                                ) : (
                                                <CiStar className="text-blue-500" />
                                            )
                                        }
                                    </div>
                                </div>
                                <div className='flex flex-row space-x-1 items-center'>
                                    <CiMail className='text-xl' />
                                    {
                                        <span className='text-gray-600 relative bottom-[0.5px]'>
                                            {
                                                typeof lead === typeof undefined 
                                                ?   <Skeleton className='w-[70px]' />
                                                :   lead.email
                                            }
                                        </span>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className=''>
                            <button onClick={makeCall} className={`rounded-full but-basic-emp-green w-9 h-9 flex items-center justify-center`}>
                                <BsTelephoneFill className="text-[16px]" />
                            </button>
                        </div>
                    </div>
                    {
                        infos.map((info, i) => (
                            <div key={i} className={`border-b border-r p-2 flex flex-col  items-center  text-center space-y-1 ${info.key === 'lang' ? '' : 'overflow-hidden'}`}> 
                                <span className={`text-sm text-gray-700 ${info.imp ? 'bg-blue-200 px-2 rounded-md text-black' : ''}`}>{info.display}</span>
                                {
                                    info.key === 'lang' ? (
                                        typeof lead === typeof undefined 
                                        ?   <Skeleton className='w-[55px]' />
                                        :   <SelectLangOne selected={lead?.lang} setSelected={setLeadLang} />
                                    ) :
                                    info.key === 'status' ? (
                                        <span className='font-semibold px-1 rounded-md'>
                                            {
                                                typeof lead === typeof undefined 
                                                ? <Skeleton className='w-[55px]' /> 
                                                : <SelectStatusOne selected={selectedStatus} setSelected={setSelectedStatus} />
                                            }
                                        </span>
                                    ) :
                                        <span className='font-semibold'>
                                            {
                                                typeof lead === typeof undefined 
                                                ? <Skeleton className='w-[55px]' /> 
                                                : info.key === 'country' ? isoToCountry[lead[info.key]] : lead[info.key]
                                            }
                                        </span>
                                }
                            </div>
                        ))
                    }
                </div>
            </div>

            <Notes leadId={leadId} />
            <div className='flex flex-col col-span-full lg:col-span-3 space-y-4'>
                <TableLayout key={forceUpdateTrans} objBase={objBaseTrans} allThs={getAllThsTrans()} fetchURL={`/lead/${leadId}/transactions`} tableType={3} title={'Transactions'} ButPop={AddTran} setSelectedStatus={setSelectedStatus} />
                <TableLayout key={forceUpdateLogs} objBase={objBaseLogs} allThs={allThsLogs} fetchURL={`/lead/${leadId}/logs`} tableType={2} title={'Logs'} />
            </div>
        </div>
    )
}

export default LeadPage;



const allThsTrans = [
    // {key: 'id', display: 'id'},
    {key: 'amount', display: 'amount'},
    {key: 'attachment', display: 'attachment'},
    {key: 'date', display: 'date'},
    {key: 'delete', display: ''},
    // {key: 'created_at', display: 'created at'},
]
const allThsLogs = [
    // {key: 'id', display: 'id'},
    {key: 'description', display: 'description'},
    {key: 'attachment', display: 'attachment'},
    {key: 'created_at', display: 'date'},
]



var infos = [
    // {key: "id", display: 'id'}, ################
    // {key: "first_name", display: 'first name', imp: true}, ############
    // {key: "last_name", display: 'last name', imp: true},###############
    // {key: "email", display: 'email'},######################

    {key: "file_name", display: 'file name', imp: true},
    {key: "brand", display: 'brand', imp: true},
    {key: "lead_source", display: 'lead source', imp: true},
    {key: "status", display: 'status', imp: true},
    {key: "country", display: 'country', imp: true},
    {key: "lang", display: 'language', imp: true},
    {key: "city", display: 'city'},
    {key: "address", display: 'address'},
    {key: "net_deposit", display: 'net deposit'},
    {key: "last_time_deposit", display: 'last time deposit'},
    {key: "original_agent", display: 'original agent'},
    {key: "total_transaction_sum", display: 'total transaction'},
    {key: "birthday", display: 'birthday'},
    {key: "created_at", display: 'created at'},
    {key: "registration_time", display: 'registration time'},
    {key: "ftd_date", display: 'ftd date'},


    // {key: "zip", display: 'zip'},
    // {key: "calls_count", display: 'calls count'},
    // {key: "lead_score", display: 'lead score'},
]