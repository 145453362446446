import { useNavigate, useParams } from 'react-router-dom';
import TableLayout from '../../../Components/handle_table/TableLayout';
import { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton'
import { get, post, put } from '../../../helpers/apiHandler';
import { FaRegEdit } from "react-icons/fa";
import SelectLevelOne from '../../../Components/handle_select_one/SelectLevelOne';
import { formDataToObject } from '../../../helpers/funcs';
import { Store } from 'react-notifications-component';
import { useRecoilState } from 'recoil';
import { leadsTable0, officesList0 } from '../../../helpers/states';
import Model from '../../../Components/Model';
import accessLevel from '../../../docs/accessLevel';
import SelectOfficeOne from '../../../Components/handle_select_one/SelectOfficeOne';
import SelectDeskOne from '../../../Components/handle_select_one/SelectDeskOne';


const UserPage = () => {
    let { userId } = useParams();
    const [user, setUser] = useState()
    const [leadsTable, setLeadsTable] = useRecoilState(leadsTable0)
    const objBaseLogs = {"description":[],"created_at":[]}
    const objBaseTrans = {"amount":[],"date":[]}

    const Navigate = useNavigate()

    const fetchUser = async () => {
        setUser()
        const result = await get(`/user/${userId}`)
        if (result.ok) {
            setUser(result.data)
        } else {
            Navigate('/users')
            setUser({})
        }
    }
    useEffect(() => {
        fetchUser()
    }, [userId])

    useEffect(() => {
        if (user !== undefined) {
            if (leadsTable['users'] !== undefined) {
                setLeadsTable(prev => {
                    var indexLead = prev['users']['data'].findIndex(item => item.id == userId)
                    var lead_from_data = prev['users']['data'][indexLead]
                    var keys = ['name', 'email', 'access_level', 'office_name', 'desk_name', 'extension']
                    var obj = {}
                    for (var key of keys) {
                        if (lead_from_data[key] !== user[key]) {
                            obj[key] = user[key]
                        }
                    }

                    var newData = [...prev['users']['data']]
                    newData[indexLead] = {
                        ...newData[indexLead],
                        ...obj
                    }
                    return {
                        ...prev,
                        users: {
                            ...prev['users'],
                            data: [...newData]
                        }
                    }
                    

                    return prev
                })
            }
        }
    }, [user])

    return (
        <div className='flex flex-col space-y-4 max-w-[1400px] mx-auto'>
                <Infos key={userId + 'infos'} user={user} setUser={setUser} />
                
                <div className='col-span-full grid grid-cols-4 gap-4'>
                    <TableLayout key={userId + 'trans'} objBase={objBaseTrans} allThs={allThsTrans} fetchURL={`/user/${userId}/transactions`} tableType={3} title={'Transactions'} />
                    <TableLayout key={userId + 'logs'} objBase={objBaseLogs} allThs={allThsLogs} fetchURL={`/user/${userId}/logs`} tableType={2} title={'Logs'} />
                </div>

        </div>
    )
}

export default UserPage;


const InfosEdit = ({user, setUser, setIsEdit}) => {
    const [isLoading, setIsLoading] = useState(false)
    // const [languages, setLanguages] = useState(user.languages.map(iso => ({value: iso, label: IsoToLang[iso]})))
    const [selectedDesk, setSelectedDesk] = useState(user?.desk_id !== null ? {value: Number(user?.desk_id), label: user?.desk_name} : {value: null, label: '-----'})
    const updateUser = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        const formData = new FormData(e.target);
        var objData = {...formDataToObject(formData), desk: selectedDesk?.value, office: selectedOffice?.value};
        console.log(objData)
        if (objData.password == '') {
            delete objData.password; 
        }
        const res = await put(`/users/${user.id}`, objData)
        if (res.ok) {
            setUser()
            setUser(res.data)
            setIsEdit(false)
            Store.addNotification({
                message: `User successfully updated`,
                type: "success",
                insert: "bottom",
                container: "bottom-right",
                dismiss: {
                  duration: 5000,
                }
            });
        } else {
            Store.addNotification({
                message: res.data.message || `somthing went wrong`,
                type: "danger",
                insert: "bottom",
                container: "bottom-right",
                dismiss: {
                  duration: 5000,
                }
            });
        }
        setIsLoading(false)
    }

    const [selectedOffice, setSelectedOffice] = useState(user?.office_id !== null ? {value: Number(user.office_id), label: user.office_name} : {value: null, label: '-----'})
    const [deskData, setDeskData] = useState([{value: null, label: '-----'}])
    const [officesList, setOfficesList] = useRecoilState(officesList0)
    useEffect(() => {
        setSelectedDesk({value: null, label: '-----'})
        if (selectedOffice.value !== null) {
            const office = officesList.find(item => item.id === selectedOffice.value)
            setDeskData([{value: null, label: '-----'}, ...office?.desks.map(des => {return {'value': des.id, 'label': des.name}})])
        } else {
            setDeskData([{value: null, label: '-----'}])
        }
    }, [selectedOffice])


    return (
        <form className='card col-span-full' autoComplete='off' onSubmit={updateUser}>
            <div className="grid grid-cols-2 gap-4">
                <InpSpan span='name' name='name' type='text' required={true} defaultValue={user['name']} />
                <InpSpan span='email' name='email' type='email' required={true} defaultValue={user['email']} />
                <InpSpan span='password' name='password' type='password' required={false} />

                <div className="flex flex-col space-y-1">
                    <span className="font-semibold text-gray-800">level</span>
                    <SelectLevelOne selected={user['access_level']} />
                </div>


                <div className="flex flex-col space-y-1">
                    <span className="font-semibold text-gray-800">office</span>
                    <SelectOfficeOne selected={selectedOffice} setSelected={setSelectedOffice} />
                </div>

                <div className="flex flex-col space-y-1">
                    <span className="font-semibold text-gray-800">desk</span>
                    <SelectDeskOne deskData={deskData} selected={selectedDesk} setSelected={setSelectedDesk} />
                </div>



                <InpSpan span='extension' name='extension' type='number' required={false} defaultValue={user['extension']} />
            </div>
            <div className="flex flex-row items-center justify-between">
                <span className="text-red-600 font-semibold text-[16px]">
                    {/* {errText} */}
                </span>
                <div className="flex flex-row space-x-2">
                    <button className="but but-basic-emp" type="button" onClick={() => setIsEdit(false)}>cancel</button>
                    <button className="but but-basic" type="submit" disabled={isLoading}>update user</button>
                </div>
            </div>
        </form>
    )
}

const Infos = ({ user, setUser }) => {
    const Navigate = useNavigate()
    const [isEdit, setIsEdit] = useState(false)
    const [leadsTable, setLeadsTable] = useRecoilState(leadsTable0)

    const [showConfirm, setShowConfirm] = useState(false)
    
    const deleteUser = async () => {
        const result = await post(`/users/delete`, {ids: [user.id]})
        if (result.ok) {
            if (leadsTable['users'] !== undefined) {
                setLeadsTable(prev => {
                    return {
                        ...prev,
                        users: undefined
                    }
                })
            }
            Store.addNotification({
                message: "users successfully deleted",
                type: "success",
                insert: "bottom",
                container: "bottom-right",
                dismiss: {
                  duration: 5000,
                }
            });
            Navigate('/users')
        } else {
            Store.addNotification({
                message: result.data.message || `somthing went wrong`,
                type: "danger",
                insert: "bottom",
                container: "bottom-right",
                dismiss: {
                  duration: 5000,
                }
            });
        }
        setShowConfirm(false)
    }

    
    return (
        <div className='grid grid-cols-2 md:grid-cols-4 gap-4'>
            {
                showConfirm && (
                    <Model  children={
                        <div className='flex flex-col space-y-2'>
                            <h2>Are you sure you want to delete this user?</h2>
                            <div className='flex flex-row justify-end space-x-3'>
                                <button className='but but-basic-emp' onClick={() => setShowConfirm(false)}>Cancel</button>
                                <button className='but but-basic-red' onClick={deleteUser}>Delete</button>
                            </div>
                        </div>
                    } setState={setShowConfirm} title={'Delete user'} />
                )
            }
            {
                isEdit ?  (
                    <>
                        <div className='col-span-full flex flex-row justify-between'>
                            <UserName name={user?.name} />
                            <button className='but but-basic-red flex flex-row items-center space-x-1' onClick={() => setShowConfirm(true)}>
                                <FaRegEdit />
                                <span>Delete user</span>
                            </button>
                        </div>
                        <InfosEdit user={user} setUser={setUser} setIsEdit={setIsEdit} />
                    </>
                ) : (
                    <>
                        <div className='col-span-full flex flex-row justify-between'>
                            <UserName name={user?.name} />
                            <button className='but but-basic flex flex-row items-center space-x-1' onClick={() => setIsEdit(true)}>
                                <FaRegEdit />
                                <span>Edit user</span>
                            </button>
                        </div>
                        {
                            infos.map((info, i) => (
                                <div key={i} className={`card p-2 flex flex-col  items-center text-center space-y-1 ${info.key === 'lang' ? '' : 'overflow-hidden'}`}>               
                                    <span className={`text-sm text-gray-900`}>{info.display}</span>
                                    <div className='h-full flex items-center'>
                                        {
                                            typeof user === typeof undefined 
                                            ? <Skeleton className='w-[55px]' />
                                            // : info.key === 'countries' ? 
                                            //     user['countries'].length === 0 
                                            //     ? (
                                            //         <span className='buble mr-1 mb-1'>all countries</span>
                                            //     ) : (
                                            //         <div className='text-sm '>
                                            //             {
                                            //                 user[info.key]?.map((iso, c) => (
                                            //                     <span key={c} className='buble mr-1 mb-1'>{isoToCountry[iso]}</span>
                                            //                 ))
                                            //             }
                                            //         </div>
                                            //     )
                                            // : info.key === 'languages' ? 
                                            //     user['languages'].length === 0 ? (
                                            //         <span className='buble mr-1 mb-1'>all languages</span>
                                            //     ) : (
                                            //         <div className='text-sm '>
                                            //             {
                                            //                 user[info.key]?.map((iso, c) => (
                                            //                     <span key={c} className='buble mr-1 mb-1'>{IsoToLang[iso]}</span>
                                            //                 ))
                                            //             }
                                            //         </div>
                                            //     )
                                            : info.key === 'access_level' ? (
                                                <span>{accessLevel.find(lev => Number(lev.value) === user['access_level']).label}</span>
                                            ) : (
                                                <span className={`text-sm text-gray-700 font-semibold text-lg`}>
                                                    {user[info.key]}
                                                </span>
                                            )
                                            
                                            
                                        }
                                    </div>
                                </div>
                            ))
                        }
                    </>
                )
            }
        </div>
    )
}


const UserName = ({name}) => {
    return (
        <h1 className="text-[24px] font-semibold">{name}</h1>
    )
}

const InpSpan = ({span, name, type, required, defaultValue}) => {
    return (
        <div className="flex flex-col space-y-1">
            <span className="font-semibold text-gray-800">{span}</span>
            <input className="inp" name={name} type={type} required={required} defaultValue={defaultValue || ''} />
        </div>
    )
}

const allThsTrans = [
    // {key: 'id', display: 'id'},
    {key: 'amount', display: 'amount'},
    {key: 'attachment', display: 'attachment'},
    {key: 'date', display: 'date'},
    // {key: 'created_at', display: 'created at'},
]

const allThsLogs = [
    // {key: 'id', display: 'id'},
    {key: 'description', display: 'description'},
    {key: 'attachment', display: 'attachment'},
    {key: 'created_at', display: 'date'},
]

const infos = [
    // {key: "id", display: 'id'},
    // {key: "name", display: 'name'},

    {key: "email", display: 'email'},
    {key: "extension", display: 'extension'},
    {key: "calls_count", display: 'calls count'},
    {key: "transaction_sum", display: 'transaction sum'},
    {key: "access_level", display: 'access level'},
    {key: "office_name", display: 'office'},
    {key: "desk_name", display: 'desk'},
]
