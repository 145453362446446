import { useNavigate, useParams } from "react-router-dom";
import LeadPage from "../leads/lead";
import { useRecoilState } from "recoil";
import { green0 } from "../../helpers/states";
import { MdPhoneInTalk } from "react-icons/md";


const LeadAuto = () => {
    const Navigate = useNavigate()
    const [green, setGreen] = useRecoilState(green0)

    const handleStart = () => {
        Navigate('/auto')
    }

    return (
        <div className="flex flex-col space-y-4">
            <div className="flex flex-row">
                <button onClick={handleStart} className='but but-basic-color bg-green-600 hover:bg-green-700 active:bg-green-800 flex flex-row items-center space-x-1'>
                    <MdPhoneInTalk className='text-[18px]' />
                    <span>AUTO DIAL</span>
                </button>
            </div>
            <LeadPage />
        </div>
    )
}

export default LeadAuto