import React, { useEffect } from 'react'

export default function BubbleFilters({filterParam, setFilterParam, allThs}) {
    const removeFilter = (item) => {
        // if (item == 'file_name') {
        //     localStorage.removeItem("fileName");
        // }
        setFilterParam(prev => {
            return {...prev, [item]: []}
        })
    }

    useEffect(() => {
        // console.log(allThs.find(th => th.key == 'office_name').display)
    }, [])
  return (
    <div className="flex flex-row space-x-1 w-full">
        {
            Object.keys(filterParam).filter(item => item !== 'favorite').map((item, i) => (
                filterParam[item].length !== 0 && (
                    <span className="buble" key={i}>
                        {allThs.find(th => th.key == item)?.display}
                        <button onClick={() => removeFilter(item)} type="button" className="inline-flex items-center p-1 ml-2 text-sm text-blue-400 bg-transparent rounded-sm hover:bg-blue-200 hover:text-blue-900" data-dismiss-target="#badge-dismiss-default" aria-label="Remove">
                            <svg className="w-2 h-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                            </svg>
                        </button>
                    </span>
                )
            ))
        }
    </div>
  )
}
