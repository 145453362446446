import { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import TableLayout from '../../Components/handle_table/TableLayout';
import { useRecoilState } from 'recoil';
import { pos0 } from '../../helpers/states';
import { updateFile_nameStorge } from '../../helpers/funcs';

const LeadsPage = () => {
    var list_file_name = []
    var fileName = localStorage.getItem("fileName")
    if (fileName == null) {
        localStorage.setItem('fileName', 'all')
        fileName = 'all'
    }
    
    if (fileName !== 'all') {
        list_file_name.push({"operator":"eq","value":fileName,"join":"and"})
    }
    const objBase = {"id":[],"file_name":list_file_name,"first_name":[],"last_name":[],"brand":[],"email":[],"status":[],"country":[],"lang":[],"registration_time":[],"ftd_date":[],"created_at":[],"net_deposit":[],"ftd_time":[],"last_time_deposit":[],"city":[],"address":[],"zip":[],"original_agent":[],"original_status":[],"birthday":[],"comment":[],"last_assign_date":[],"lead_source":[], "favorite": []}
    const Navigate = useNavigate()



    if (localStorage.getItem('leadsFilter') !== null) {
        updateFile_nameStorge(list_file_name)
    }



    // handle pos X
    const [pos, setPos] = useRecoilState(pos0)
    const scroledDiv = useRef(null);
    useEffect(() => {
        function handleScroll() {
            if (scroledDiv.current) {
                const { scrollTop } = scroledDiv.current;
                if (scrollTop > 10) {
                    setPos(scrollTop)
                }
            }
        }
        if (scroledDiv.current) {
            scroledDiv.current.addEventListener('scroll', handleScroll);
        }
        return () => {
            if (scroledDiv.current) {
                scroledDiv.current.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);
    const location = useLocation()
    useEffect(() => {
        if (scroledDiv.current !== null) {
            scroledDiv.current.scrollTop = pos;
        }
    }, [location])
    //



    if (fileName !== null) {
        return <TableLayout objBase={objBase} allThs={allThs} fetchURL={'/leads'} tableType={0} scroledDiv={scroledDiv} />
    }
}


export default LeadsPage;


const allThs = [
    {key: 'id', display: 'id'},
    {key: 'first_name', display: 'first name'},
    {key: 'last_name', display: 'last name'},
    {key: 'phone', display: 'phone'},
    {key: 'brand', display: 'brand'},
    {key: 'email', display: 'email'},
    {key: 'status', display: 'status'},
    {key: 'country', display: 'country'},
    {key: 'lang', display: 'language'},
    {key: 'registration_time', display: 'registration time'},
    {key: 'created_at', display: 'created at'},
    {key: 'transaction_sum', display: 'transaction sum'},
    {key: 'net_deposit', display: 'net_deposit'},
    {key: 'ftd_date', display: 'ftd time'},
    {key: 'last_time_deposit', display: 'last time deposit'},
    {key: 'city', display: 'city'},
    {key: 'address', display: 'address'},
    {key: 'zip', display: 'zip'},
    {key: 'original_agent', display: 'original agent'},
    {key: 'original_status', display: 'original status'},
    {key: 'birthday', display: 'birthday'},
    {key: 'comment', display: 'comment'},
    {key: 'lead_source', display: 'lead source'},
    {key: 'file_name', display: 'file name'}
]
