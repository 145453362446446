import axios from "axios";
const BASE_URL = "https://api.azurecrm.co";

// Define an object to store ongoing requests and their associated controllers
const ongoingRequests = {};

export async function get(endpoint, params) {
  // Create a new AbortController instance for each request
  const controller = new AbortController();
  
  // Store the controller in the ongoingRequests object
  ongoingRequests[endpoint] = controller;

  const token = localStorage.getItem('authToken');
  const config = {
    headers: {
      "authorization": `Bearer ${token}`,
      withCredentials: true,
    },
    signal: controller.signal // Pass the signal to the request config
  };

  try {
    let paramKeys = [];
    if (params) {
      Object.keys(params).map(key => {
        paramKeys.push(key + '=' + params[key]);
        return paramKeys;
      });
    }
    const queryString = paramKeys && paramKeys.length ? paramKeys.join('&') : "";
    const response = await axios.get(`${BASE_URL}${endpoint}?${queryString}`, config);
    return {
      data: response.data,
      ok: true,
      cancel: false
    };
  } catch (error) {
    if (axios.isCancel(error)) {
      return {
        cancel: true
      }
    } else {
      return {
        data: error.response.data,
        ok: false,
        cancel: false
      };
    }
  } finally {
    delete ongoingRequests[endpoint]; // Clean up the aborted request
  }
}

export function abortRequest(endpoint) {
  if (ongoingRequests[endpoint]) {
    ongoingRequests[endpoint].abort();
    delete ongoingRequests[endpoint]; // Clean up the aborted request
  }
}

export async function post(endpoint, body) {
  const token = localStorage.getItem('authToken')
  const config = {
    headers: {
      "authorization": `Bearer ${token}`,
      withCredentials: true,
    }
  };
  try {
      const response = await axios.post(`${BASE_URL}${endpoint}`, body, config);
      return {
        data: response.data,
        ok: true
      }
    } catch (error) {
      return {
        data: error.response.data,
        ok: false
      }
  }
}

export async function put(endpoint, body) {
  const token = localStorage.getItem('authToken')
  const config = {
    headers: {
      "authorization": `Bearer ${token}`,
      withCredentials: true,
    }
  };
  try {
      const response = await axios.put(`${BASE_URL}${endpoint}`, body, config);
      return {
        data: response.data,
        ok: true
      }
    } catch (error) {
      return {
        data: error.response.data,
        ok: false
      }
  }
}



export async function del(endpoint) {
  const token = localStorage.getItem('authToken')
  const config = {
    headers: {
      "authorization": `Bearer ${token}`,
      withCredentials: true,
    }
  };
  try {
    const response = await axios.delete(`${BASE_URL}${endpoint}`, config);
    // response.ok = response.status >= 200 && response.status < 300;
    return {
      data: response.data,
      ok: true
    }
  } catch (error) {
    return {
      data: error.response.data,
      ok: false
    }
  }
}
