

import { FaRegEdit } from "react-icons/fa";
import { Link, useNavigate, useParams } from "react-router-dom";
import { del, get, post, put } from "../../helpers/apiHandler";
import { useEffect, useState } from "react";
import Skeleton from 'react-loading-skeleton'
import { Store } from 'react-notifications-component';
import Model from "../../Components/Model";
import SearchInp from "../../Components/handle_select_one/SearchInp";
import { FaPlus } from "react-icons/fa6";
import { formDataToObject } from "../../helpers/funcs";
import Columns from "../../Components/charts/Columns";
// types: ["default", "success", "info", "warning", "danger"],



const DeskPage = () => {
    let { officeId, deskId } = useParams();
    const [desk, setDesk] = useState()
    const Navigate = useNavigate()

    const fetchDesk = async () => {
        const res = await get(`/desk/${deskId}`)
        if (res.ok) {
            if (res.data['office_id'] === officeId) {
                setDesk(res.data)
                return;
            }
        }
        setDesk({})
        Navigate('/offices')
        
    }
    const [isEdit, setIsEdit] = useState(false)

    useEffect(() => {
        fetchDesk()
    }, [])


    // DELETE OFFICE
    const [showConfirm, setShowConfirm] = useState(false)
    const deleteDesk = async () => {
        const result = await del(`/desk/${deskId}`)
        if (result.ok) {
            Store.addNotification({
                message: "deskId successfully deleted",
                type: "success",
                insert: "bottom",
                container: "bottom-right",
                dismiss: {
                  duration: 5000,
                }
            });
            Navigate('/offices')
        } else {
            Store.addNotification({
                message: result.data.message || `somthing went wrong`,
                type: "danger",
                insert: "bottom",
                container: "bottom-right",
                dismiss: {
                  duration: 5000,
                }
            });
        }
        setShowConfirm(false)
    }





    return (
        <div className="flex flex-col space-y-4 md:space-y-0 md:flex-row md:space-x-4 max-w-[1400px] mx-auto">
            {
                showConfirm && (
                    <Model  children={
                        <div className='flex flex-col space-y-2'>
                            <h2>Are you sure you want to delete this Desk?</h2>
                            <div className='flex flex-row justify-end space-x-3'>
                                <button className='but but-basic-emp' onClick={() => setShowConfirm(false)}>Cancel</button>
                                <button className='but but-basic-red' onClick={deleteDesk}>Delete</button>
                            </div>
                         </div>
                    } setState={setShowConfirm} title={'Delete user'} />
                )
            }
            
            <div className="w-full md:w-3/5 space-y-4" style={{height: 'fit-content'}}>

                <div className="flex flex-row justify-between items-center">
                    <h1 className="text-[24px] font-semibold">
                        {
                            typeof desk === typeof undefined ? (
                                <Skeleton className="w-[150px] h-[30px]" />
                            ) : (
                                desk?.['name']
                            )
                        }
                    </h1>

                    {
                        isEdit ? (
                            <div className='flex flex-row justify-end'>
                                <button className={`but but-basic-red flex flex-row items-center space-x-1`} onClick={() => setShowConfirm(true)}>
                                    <FaRegEdit />
                                    <span>Delete desk</span>
                                </button>
                            </div>

                        ) : (
                            <div className='flex flex-row justify-end'>
                                <button className={`but but-basic flex flex-row items-center space-x-1`} onClick={() => setIsEdit(true)}>
                                    <FaRegEdit />
                                    <span>Edit desk</span>
                                </button>
                            </div>
                        )
                    }


                </div>

                {
                    isEdit ? (
                        <InfoEdit desk={desk} setIsEdit={setIsEdit} setDesk={setDesk} />
                    ) : (
                        <div className='grid grid-cols-2 lg:grid-cols-3 gap-4'>
                            {
                                infos.map((info, i) => (
                                    <div key={i} className={`card border-b border-r p-2 flex flex-col  items-center  text-center space-y-1 ${info.key === 'lang' ? '' : 'overflow-hidden'}`}> 
                                        <span className={`text-sm text-gray-700`}>{info.display}</span>
                                    
                                        <div className='h-full flex items-center'>
                                            {
                                                typeof desk === typeof undefined 
                                                ? <Skeleton className='w-[55px]' />
                                                : info.key === 'office' ? (
                                                    <span>{desk[info.key].name}</span>
                                                ) 
                                                : info.key === 'users_count' ? (
                                                    <span>{[...desk?.agents, ...desk?.desk_managers].length}</span>
                                                ) : (
                                                <span className={`text-sm text-gray-700 font-semibold text-lg`}>
                                                    {desk[info.key]}
                                                </span>
                                                )
                                            }
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    )
                }

                <div className="card">
                    <Columns data={desk?.lead_status_counts} />
                </div>
            </div>


            <div className='w-full md:w-2/5 flex flex-col space-y-4'>
                <div className="flex flex-row justify-between space-x-4">
                    <button className="but but-basic  invisible" type="button">
                        <span className='tracking-wide'>nothong</span>
                    </button>
                </div>
                    {
                        typeof desk == typeof undefined ? (
                            <div className="card font-semibold">
                                Loading...
                            </div> 
                        ) : 
                        
                        [...desk?.agents, ...desk?.desk_managers].length === 0 ? (
                            <div className="card font-semibold">
                                no users
                            </div>
                        ) :
                        
                        <div className="card flex flex-col space-y-2">
                            {
                                [...desk?.agents, ...desk?.desk_managers].map((user, a) => (
                                    <Link key={a} to={`/users/${user.id}`} className="flex flex-row space-x-2 items-center hover:bg-gray-100 py-1">
                                        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" className="text-[#405189]" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M12 18a6 6 0 1 0 0-12 6 6 0 0 0 0 12Z"></path></svg>
                                        <span className="text-lg">{user.name}</span>
                                    </Link>
                                ))
                            }
                        </div>
                    }
            </div>


        </div>
    )
}

export default DeskPage

const InfoEdit = ({desk, setIsEdit, setDesk}) => {
    const [isLoading, setIsLoading] = useState(false)
    const [errText, setErrText] = useState('')

    const updateDesk = async (e) => {
        e.preventDefault()

        const formData = new FormData(e.target);
        const objData = formDataToObject(formData);
        const result = await put(`/desk/${desk?.id}`, objData);

        if (result.ok) {
            setDesk(prev => {
                return {
                    ...prev, 
                    ...result.data.data
                }
            })
            Store.addNotification({
                message: result.data.message || `Desk update successfully`,
                type: "success",
                insert: "bottom",
                container: "bottom-right",
                dismiss: {
                  duration: 5000,
                }
            });
            setIsEdit(false)
        } else {
            Store.addNotification({
                message: result.data.message || `somthing went wrong`,
                type: "danger",
                insert: "bottom",
                container: "bottom-right",
                dismiss: {
                  duration: 5000,
                }
            });
        }




    }



    return (
        <form className="card space-y-4 max-w-[1400px] mx-auto" onSubmit={updateDesk} autoComplete="off">
            <div className="grid grid-cols-2 gap-4">
                <InpSpan span='name' name='name' type='text' required={true} defaultValue={desk?.['name']} />
            </div>
            <div className="flex flex-row items-center justify-between">
                <span className="text-red-600 font-semibold text-[16px]">
                    {errText}
                </span>
                <div className="flex flex-row space-x-2">
                    <button className="but but-basic-emp" type="button" onClick={() => setIsEdit(false)}>cancel</button>
                    <button className="but but-basic" type="submit" disabled={isLoading}>update Desk</button>
                </div>
            </div>
        </form>
    )
}

const InpSpan = ({span, name, type, required, defaultValue}) => {
    return (
        <div className="flex flex-col space-y-1">
            <span className="font-semibold text-gray-800">{span}</span>
            <input className="inp" name={name} type={type} required={required} defaultValue={defaultValue || ''} />
        </div>
    )
}



const infos = [
    {key: "office", display: 'office'},
    {key: "users_count", display: 'users amount'},
    {key: "total_transaction_sum", display: 'total transactions'},

]
