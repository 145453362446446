import { RiDashboard2Fill, RiLogoutBoxRLine } from 'react-icons/ri';
import { MdPermContactCalendar } from 'react-icons/md';
import { FaUserShield } from 'react-icons/fa';
import { BiSolidBriefcase } from 'react-icons/bi';
import { AiFillSetting } from 'react-icons/ai';
import { CgProfile } from 'react-icons/cg'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { IoIosSettings } from "react-icons/io";
import { useRecoilState } from 'recoil';
import { currentUser0, leadsTable0, pos0 } from '../helpers/states';
import { logout_func } from '../helpers/funcs';
import { PiFilesFill } from "react-icons/pi";
import { MdPhoneInTalk } from "react-icons/md";





const Menu = () => {
    const Location = useLocation();
    const [show, setShow] = useState(false)
    const randomId = 'sett_menu'
    const navigate = useNavigate()
    const [currentUser, setCurrentUser] = useRecoilState(currentUser0)


    useEffect(() => {
        if (show) {
            const handleBodyClick = (e) => {
                var element = e.target
                var isOut = true;
                while (true) {
                    if (element.id == randomId || element.id == `but-${randomId}`) {
                        isOut = false;
                        break;
                    };
                    element = element.parentElement
                    if (element == null) break;
                }
                if (isOut) setShow(false);
            };
                document.body.addEventListener('click', handleBodyClick);
            return () => {
                document.body.removeEventListener('click', handleBodyClick);
            };
        }
    }, [show])

    return (
        <div style={{backgroundImage: "url('/snow.jpg')"}} className="main-height sticky top-0 text-[#98a4cf] z-30">
            <div className='w-[70px] flex flex-col justify-between main-height bg-[#405189] bg-opacity-90'>
                
                <div>
                </div>               
                {/* <div className={`w-full h-[70px] flex items-center justify-center hover:text-white`}>
                    <Box link='/auto' icon={<MdPhoneInTalk className='text-[30px]' />} />
                </div> */}
        

                <div className='flex flex-col items-center w-full'>
                    <Box link='/files' icon={<PiFilesFill className='text-[30px]' />} />
                    <Box link='/leads' icon={<MdPermContactCalendar className='text-[30px]' />} />
                    <Box link='/users' icon={<FaUserShield className='text-[30px]' />} />
                    <Box link='/offices' icon={<BiSolidBriefcase className='text-[30px]' />} />
                </div>

                <div className='sett_but_menu cursor-pointer' onClick={() => setShow(prev => !prev)} id={`but-${randomId}`}>
                    <div className={`w-full h-[70px] flex items-center justify-center hover:text-white`}>
                        <IoIosSettings className='text-[30px]' />
                    </div>
                </div>


                {
                    show && (
                        <div className='absolute bottom-[5px] left-[75px]' id={`${randomId}`}>
                            <div className='card border w-[150px] p-0 py-1'>
                                <ul className='text-[16px] text-gray-700 [&>li]:p-3 [&>li]:cursor-pointer font-semibold'>
                                    <li onClick={() => {setShow(false); navigate(`/users/${currentUser.id}`)}} className='hover:bg-gray-100 flex flex-row items-center space-x-3 active:bg-gray-200'>
                                        <CgProfile className='text-[20px]' />
                                        <span>
                                            Profile
                                        </span>
                                    </li>
                                    <li className='hover:bg-gray-100 flex flex-row items-center space-x-3 active:bg-gray-200' onClick={logout_func}>
                                        <RiLogoutBoxRLine className='text-[20px]' />
                                        <span>
                                            Logout
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    )
                }
                
            </div>
        </div>
    )
}

const Box = ({icon, link, link0}) => {
    const [leadsTable, setLeadsTable] = useRecoilState(leadsTable0)
    const [pos, setPos] = useRecoilState(pos0)
    const Location = useLocation();
    return (
        // bg-[#39487b]
        <Link 
            onClick={() => {setLeadsTable({"leads": undefined,"users": undefined}); setPos(0);}} 
            to={link} className={`${Location.pathname.includes(link) || Location.pathname.includes(link0) ? 'text-white' : ''} w-full h-[70px] flex items-center justify-center hover:text-white`}>
                {icon}
        </Link>
    )
}

export default Menu