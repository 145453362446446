import { useEffect, useState } from "react"
import { PiNoteLight } from 'react-icons/pi';
import { Link, useParams } from "react-router-dom";
import Skeleton from 'react-loading-skeleton'
import { formDataToObject } from "../../../helpers/funcs";
import { del, get, post } from "../../../helpers/apiHandler";
import { Store } from 'react-notifications-component';
import { RiDeleteBin6Line } from "react-icons/ri";
import Model from "../../../Components/Model";



const Notes = () => {
    let { leadId } = useParams();
    const [notes, setNotes] = useState()
    const [isLoading, setIsLoading] = useState(false)

    const fetchNotes = async () => {
        const result = await get(`/lead/${leadId}/notes`);
        if (result.ok) {
            setNotes(result.data.data)
        } else {
            setNotes([])
        }
        setIsCreate(false)
        setIsLoading(false)
    }
    useEffect(() => {
        fetchNotes()
    }, [])

    const [isCreate, setIsCreate] = useState(false)

    const createNote = async (e) => {
        e.preventDefault();
        setIsLoading(true)
        const formData = new FormData(e.target);
        const objData = formDataToObject(formData);
        const result = await post(`/lead/${leadId}/note`, objData);
        if (result.ok) {
            fetchNotes()
            Store.addNotification({
                message: "Note successfully created",
                type: "success",
                insert: "bottom",
                container: "bottom-right",
                dismiss: {
                  duration: 5000,
                }
            });
        } else {
            Store.addNotification({
                message: result.data.message || `somthing went wrong`,
                type: "danger",
                insert: "bottom",
                container: "bottom-right",
                dismiss: {
                  duration: 5000,
                }
            });
        }
        setIsLoading(false)
    }


    return (
        <div className='col-span-full lg:col-span-2 row-span-1 lg:row-span-3 space-y-4'>
            <div className="flex flex-row justify-between">
                <div className="flex flex-row space-x-2 items-center">
                    <span className='text-[22px] font-semibold whitespace-nowrap'>Notes</span>
                </div>
                <div className="flex flex-row space-x-2">

                    <button onClick={() => setIsCreate(true)} className={`${isCreate ? 'invisible' : ''} but but-basic flex flex-row items-center space-x-0.5`}>
                        <span>+ create note</span>
                    </button>

                </div>
            </div>

            {
                isCreate && (
                    <form className="card flex flex-col space-y-4" onSubmit={createNote}>
                        <input name="title" className="inp" placeholder="Title" />
                        <textarea name="note" className="inp py-3 h-[150px]" placeholder="Type your note"></textarea>
                        <div className="flex flex-row space-x-2 justify-end">
                            <button type="button" onClick={() => setIsCreate(false)} className="but but-basic-emp">cancel</button>
                            <button type="submit" className="but but-basic" disabled={isLoading}>Create note</button>
                        </div>
                    </form>
                )
            }

            {
                (notes?.length === 0 && !isCreate) && (
                    <div>
                        <span>No notes yet</span>
                    </div>
                )
            }

            {
                notes?.map((note, n) => (
                    <Note key={n} note={note} fetchNotes={fetchNotes} />
                ))
            }

            {
                typeof notes === typeof undefined && (
                    <NoteLoading />
                )
            }
        </div>
    )
}

export default Notes



const Note = ({note, fetchNotes}) => {
    let { leadId } = useParams();

    const isTextLong = (text) => {
        const lines = text.split('\n');
        if (text > 100) return true;
        return lines.length > 1;
    
    };
    const [showFull, setShowFull] = useState(false)
    const [isLong, setIsLong] = useState(isTextLong(note?.note))



    ////////////// REMOVE NOTE ///////////////

    const [showConfirm, setShowConfirm] = useState(false)
    const removeNote = async () => {
        const result = await del(`/lead/${leadId}/note/${note.id}`)
        if (result.ok) {
            fetchNotes()
            Store.addNotification({
                message: "Note successfully deleted",
                type: "success",
                insert: "bottom",
                container: "bottom-right",
                dismiss: {
                  duration: 5000,
                }
            });
        } else {
            Store.addNotification({
                message: "Somthing went wrong",
                type: "danger",
                insert: "bottom",
                container: "bottom-right",
                dismiss: {
                    duration: 5000,
                }
            });
        }
    }


    return (
        <div className='card p-0'>
            {
                showConfirm && (
                    <Model  children={
                        <div className='flex flex-col space-y-2'>
                            <h2>Are you sure you want to delete this note?</h2>
                            <div className='flex flex-row justify-end space-x-3'>
                                <button className='but but-basic-emp' onClick={() => setShowConfirm(false)}>Cancel</button>
                                <button className='but but-basic-red' onClick={removeNote}>Delete</button>
                            </div>
                        </div>
                    } setState={setShowConfirm} title={'Delete user'} />
                )
            }
            <div className="flex flex-col">

                <div className="flex flex-row justify-between space-x-2 p-4 border-b">
                    <div className="flex flex-row space-x-2 items-center">
                        <div className='rounded-full bg-gray-100 !w-[35px] !h-[35px] px-1 text-blue-600 flex items-center justify-center'>
                            <PiNoteLight className='text-[20px] relative left-[1px]' />
                        </div>
                        <div className="flex flex-col">
                            <span className="font-semibold">{note.title}</span>
                            <Link to={`/users/${note.user.id}`} className="text-gray-500 font-semibold text-[15px]">
                                @{note.user.name}
                            </Link>
                        </div>
                    </div>
                    {/* <div className="flex flex-row items-end space-y-1">
                    </div> */}
                    <div className="flex flex-row items-center space-x-1">
                        <span className="whitespace-nowrap font-semibold text-gray-800 relative bottom-[0.5px]">{note.formatted_created_at}</span>

                        <button onClick={() => setShowConfirm(true)} className={`rounded-full but-basic-emp-emp w-6 h-6 flex items-center justify-center relative top-[1px] hoverEffect2`}>
                            <RiDeleteBin6Line className="text-[16px]" />
                        </button>
                    </div>
                </div>

                <div className="p-4 whitespace-pre-wrap">
                    {
                        isLong ? (
                            showFull ? (
                                <div>{note.note}</div>
                            ) : (
                                <div>
                                    {
                                        note.note.split('\n').length > 1 ? (
                                            note.note.split('\n')[0].substring(0,35) + '...' 
                                            ): (
                                            note.note.substring(0,10) + '...'
                                        )
                                    }
                                </div>
                            )
                        ) : (
                            <div>
                                {note.note}
                            </div>
                        )
                    }
                    {
                        isLong && (
                            <div className="flex justify-end text-blue-600 hover:underline">
                                {
                                    showFull ? (
                                        <button onClick={() => setShowFull(false)}>Read less..</button>
                                    ) : (
                                        <button onClick={() => setShowFull(true)}>Read more..</button>
                                    )
                                }
                            </div>
                        )
                    }
                    

                </div>

            </div>
        </div>
    )
}


const NoteLoading = () => {
    return (
        <div className='card p-0'>
            <div className="flex flex-col">
                <div className="flex flex-row justify-between space-x-2 p-4 border-b">
                    <div className="flex flex-row space-x-2 items-center">
                        <div className='rounded-full bg-gray-100 !w-[35px] !h-[35px] px-1 text-blue-600 flex items-center justify-center'>
                            <PiNoteLight className='text-[20px] relative left-[1px]' />
                        </div>
                        <div className="flex flex-col">
                            <Skeleton className="w-20" />
                            <Skeleton className="w-20" />
                        </div>
                    </div>
                    <span className="">
                        <Skeleton className="w-20" />
                    </span>
                </div>
                <div className="p-4 whitespace-pre-wrap">
                    <Skeleton className="w-40" />
                </div>
            </div>
        </div>
    )
}