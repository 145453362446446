
import { FaRegEdit } from "react-icons/fa";
import { Link, useNavigate, useParams } from "react-router-dom";
import { del, get, post, put } from "../../../helpers/apiHandler";
import { useEffect, useState } from "react";
import Skeleton from 'react-loading-skeleton'
import { Store } from 'react-notifications-component';
import Model from "../../../Components/Model";
import SearchInp from "../../../Components/handle_select_one/SearchInp";
import { FaPlus } from "react-icons/fa6";
import { formDataToObject } from "../../../helpers/funcs";
import Columns from "../../../Components/charts/Columns";
// types: ["default", "success", "info", "warning", "danger"],



const OfficePage = () => {
    let { officeId } = useParams();
    const [office, setOffice] = useState()
    const [isEdit, setIsEdit] = useState(false)
    const Navigate = useNavigate()

    const fetchOffice = async () => {
        const res = await get(`/office/${officeId}`)
        if (res.ok) {
            setOffice(res.data)
        }   else {
            Navigate('/offices')
            setOffice({})
        }
    }
    useEffect(() => {
        fetchOffice()
    }, [])


    // DELETE OFFICE
    const [showConfirm, setShowConfirm] = useState(false)
    const deleteOffice = async () => {
        const result = await del(`/office/${officeId}`)
        if (result.ok) {
            Store.addNotification({
                message: "office successfully deleted",
                type: "success",
                insert: "bottom",
                container: "bottom-right",
                dismiss: {
                  duration: 5000,
                }
            });
            Navigate('/offices')
        } else {
            Store.addNotification({
                message: result.data.message || `somthing went wrong`,
                type: "danger",
                insert: "bottom",
                container: "bottom-right",
                dismiss: {
                  duration: 5000,
                }
            });
        }
        setShowConfirm(false)
    }

    const [searchText, setTextSearch] = useState('')

    const [deskListAfter, setDeskListAfter] = useState([])
    useEffect(() => {
        if (typeof office?.desks !== typeof undefined) {
            var res = office?.desks.filter(office => office.name.toLowerCase().includes(searchText.toLowerCase()))
            setDeskListAfter(res)
        }
    }, [searchText, office?.desks])

    const [isCreateDesk, setIsCreateDesk] = useState(false)
    const createDesk = async (e) => {
        e.preventDefault()
        const formData = new FormData(e.target);
        const objData = formDataToObject(formData);
        const result = await post(`/desks`, {...objData, office_id: officeId});
        if (result.ok) {
            Store.addNotification({
                message: "Desk successfully created",
                type: "success",
                insert: "bottom",
                container: "bottom-right",
                dismiss: {
                  duration: 5000,
                }
            });
            setOffice(prev => {
                return {
                    ...prev,
                    desks: [...prev.desks, result.data.desk]
                }
            })
            setIsCreateDesk(false)
        } else {
            Store.addNotification({
                message: result.data.message || `somthing went wrong`,
                type: "danger",
                insert: "bottom",
                container: "bottom-right",
                dismiss: {
                  duration: 5000,
                }
            });
        }
    }



    return (
        <div className="flex flex-col space-y-4 md:space-y-0 md:flex-row md:space-x-4 max-w-[1400px] mx-auto">
            {
                showConfirm && (
                    <Model  children={
                        <div className='flex flex-col space-y-2'>
                            <h2>Are you sure you want to delete this Office?</h2>
                            <div className='flex flex-row justify-end space-x-3'>
                                <button className='but but-basic-emp' onClick={() => setShowConfirm(false)}>Cancel</button>
                                <button className='but but-basic-red' onClick={deleteOffice}>Delete</button>
                            </div>
                         </div>
                    } setState={setShowConfirm} title={'Delete user'} />
                )
            }
            
            <div className="w-full md:w-3/5 space-y-4" style={{height: 'fit-content'}}>

                <div className="flex flex-row justify-between items-center">
                    <h1 className="text-[24px] font-semibold">
                        {
                            typeof office === typeof undefined ? (
                                <Skeleton className="w-[150px] h-[30px]" />
                            ) : (
                                office?.['name']
                            )
                        }
                    </h1>

                    {
                        isEdit ? (
                            <div className='flex flex-row justify-end'>
                                <button className={`but but-basic-red flex flex-row items-center space-x-1`} onClick={() => setShowConfirm(true)}>
                                    <FaRegEdit />
                                    <span>Delete office</span>
                                </button>
                            </div>

                        ) : (
                            <div className='flex flex-row justify-end'>
                                <button className={`but but-basic flex flex-row items-center space-x-1`} onClick={() => setIsEdit(true)}>
                                    <FaRegEdit />
                                    <span>Edit office</span>
                                </button>
                            </div>
                        )
                    }


                </div>

                {
                    isEdit ? (
                        <InfoEdit office={office} setOffice={setOffice} setIsEdit={setIsEdit} />
                    ) : (
                        <div className='grid grid-cols-2 lg:grid-cols-3 gap-4'>
                            {
                                infos.map((info, i) => (
                                    <div key={i} className={`card border-b border-r p-2 flex flex-col  items-center  text-center space-y-1 ${info.key === 'lang' ? '' : 'overflow-hidden'}`}> 
                                        <span className={`text-sm text-gray-700`}>{info.display}</span>
                                    
                                        <div className='h-full flex items-center'>
                                            {
                                                typeof office === typeof undefined 
                                                ? <Skeleton className='w-[55px]' />
                                                : info.key == 'desks' ? (
                                                    <span className={`text-sm text-gray-700 font-semibold text-lg`}>
                                                        {office.desks.length}
                                                    </span>    
                                                ) : (
                                                <span className={`text-sm text-gray-700 font-semibold text-lg`}>
                                                    {office[info.key]}
                                                </span>
                                                )
                                            }
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    )
                }

                <div className="card">
                    <Columns data={office?.lead_status_counts} />
                </div>
            </div>


            <div className='w-full md:w-2/5 flex flex-col space-y-4'>
                <div className="flex flex-row justify-between space-x-4">
                    <SearchInp white={true} text={searchText} setText={setTextSearch} />
                    {
                        !isCreateDesk && (
                            <button className="but but-basic flex flex-row items-center space-x-1" type="button" onClick={() => setIsCreateDesk(true)}>
                                <FaPlus />
                                <span className='tracking-wide'>Create desk</span>
                            </button>
                        )
                    }
                </div>
                {
                    isCreateDesk && (
                        <form className="card flex flex-col space-y-2" autoComplete="off" onSubmit={createDesk}>
                            <div className="flex flex-col space-y-1">
                                <span className="font-semibold text-gray-800">desk's name</span>
                                <input className="inp" name={'name'} type={'text'} required={true} />
                            </div>
                            <div className="space-x-2 flex justify-end">
                                <button type="button" className="but but-basic-emp" onClick={() => setIsCreateDesk(false)}>Cancel</button>
                                <button className="but but-basic">Create Desk</button>
                            </div>
                        </form>
                    )
                }
                    {
                        typeof office == typeof undefined ? (
                            <div className="card font-semibold">
                                Loading...
                            </div> 
                        ) : 
                        
                        deskListAfter.length === 0 ? (
                            <div className="card font-semibold">
                                no desks
                            </div>
                        ) :
                        
                        <div className="card flex flex-col space-y-2">
                            {
                                deskListAfter.map((desk, a) => (
                                    <Link key={a} to={`/offices/${officeId}/${desk.id}`} className="flex flex-row space-x-2 items-center hover:bg-gray-100 py-1">
                                        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" className="text-[#405189]" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M12 18a6 6 0 1 0 0-12 6 6 0 0 0 0 12Z"></path></svg>
                                        <span className="text-lg">{desk.name}</span>
                                    </Link>
                                ))
                            }
                        </div>
                    }
            </div>


        </div>
    )
}

export default OfficePage

const InfoEdit = ({office, setIsEdit, setOffice}) => {
    const [isLoading, setIsLoading] = useState(false)
    const [errText, setErrText] = useState('')

    const updateOffice = async (e) => {
        e.preventDefault()
        const formData = new FormData(e.target);
        const objData = formDataToObject(formData);
        const result = await put(`/office/${office?.id}`, objData);

        if (result.ok) {
            setOffice(prev => {
                return {
                    ...prev, 
                    ...result.data.data
                }
            })
            Store.addNotification({
                message: result.data.message || `Office update successfully`,
                type: "success",
                insert: "bottom",
                container: "bottom-right",
                dismiss: {
                  duration: 5000,
                }
            });
            setIsEdit(false)
        } else {
            Store.addNotification({
                message: result.data.message || `somthing went wrong`,
                type: "danger",
                insert: "bottom",
                container: "bottom-right",
                dismiss: {
                  duration: 5000,
                }
            });
        }



    }

    return (
        <form className="card space-y-4 max-w-[1400px] mx-auto" onSubmit={updateOffice} autoComplete="off">
            <div className="grid grid-cols-2 gap-4">
                <InpSpan span='name' name='name' type='text' required={true} defaultValue={office['name']} />
                <InpSpan span='click2call' name='click2call' type='text' required={false} defaultValue={office['click2call']} />
            </div>
            <div className="flex flex-row items-center justify-between">
                <span className="text-red-600 font-semibold text-[16px]">
                    {errText}
                </span>
                <div className="flex flex-row space-x-2">
                    <button className="but but-basic-emp" type="button" onClick={() => setIsEdit(false)}>cancel</button>
                    <button className="but but-basic" type="submit" disabled={isLoading}>update office</button>
                </div>
            </div>
        </form>
    )
}

const InpSpan = ({span, name, type, required, defaultValue}) => {
    return (
        <div className="flex flex-col space-y-1">
            <span className="font-semibold text-gray-800">{span}</span>
            <input className="inp" name={name} type={type} required={required} defaultValue={defaultValue || ''} />
        </div>
    )
}





const infos = [
    {key: "agents_count", display: 'agent amount'},
    {key: "desks", display: 'desks amount'},
    {key: "total_transactions", display: 'total transactions'},

]
