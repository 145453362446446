import Select from 'react-select';
import { officesList0 } from '../../helpers/states';
import { useRecoilState } from 'recoil';
import { useEffect, useState } from 'react';

const SelectOffices = ({selected, setSelected}) => {
    const [officesList, setOfficesList] = useRecoilState(officesList0)
    const [data, setData] = useState([])

    // useEffect(() => {
    //     console.log(selected)
    // }, [selected])
    
    useEffect(() => {
        if (officesList !== undefined) {
            setData(
                officesList.map(office => {
                    return { value: office.id, label: office.name}
                })
            )
        }
    }, [officesList])

    const handleListChange = (selectedOptions) => {
        setSelected(selectedOptions);
    };
    
    return (
        <div className='text-gray-800 text-[14px]'>
            <Select
                defaultValue={selected}
                onChange={handleListChange}
                isMulti
                closeMenuOnSelect={false}
                name="colors"
                options={data}
                className="basic-multi-select"
                classNamePrefix="select"
            />
        </div>
    )
}


export default SelectOffices